@font-face {
    font-family: 'Helvetica Now Micro';
    src: url(/fonts/HelveticaNowMicro-ExtBdIta.eot);
    src: local('Helvetica Now Micro ExtBd Ita'), local('HelveticaNowMicro-ExtBdIta'),
        url(/fonts/HelveticaNowMicro-ExtBdIta.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowMicro-ExtBdIta.woff2) format('woff2'),
        url(/fonts/HelveticaNowMicro-ExtBdIta.woff) format('woff'),
        url(/fonts/HelveticaNowMicro-ExtBdIta.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Micro';
    src: url(/fonts/HelveticaNowMicro-Light.eot);
    src: local('Helvetica Now Micro Light'), local('HelveticaNowMicro-Light'),
        url(/fonts/HelveticaNowMicro-Light.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowMicro-Light.woff2) format('woff2'),
        url(/fonts/HelveticaNowMicro-Light.woff) format('woff'),
        url(/fonts/HelveticaNowMicro-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Micro';
    src: url(/fonts/HelveticaNowMicro-MedIta.eot);
    src: local('Helvetica Now Micro Med Ita'), local('HelveticaNowMicro-MedIta'),
        url(/fonts/HelveticaNowMicro-MedIta.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowMicro-MedIta.woff2) format('woff2'),
        url(/fonts/HelveticaNowMicro-MedIta.woff) format('woff'),
        url(/fonts/HelveticaNowMicro-MedIta.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Micro';
    src: url(/fonts/HelveticaNowMicro-ExtLtIta.eot);
    src: local('Helvetica Now Micro ExtLt Ita'), local('HelveticaNowMicro-ExtLtIta'),
        url(/fonts/HelveticaNowMicro-ExtLtIta.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowMicro-ExtLtIta.woff2) format('woff2'),
        url(/fonts/HelveticaNowMicro-ExtLtIta.woff) format('woff'),
        url(/fonts/HelveticaNowMicro-ExtLtIta.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Micro';
    src: url(/fonts/HelveticaNowMicro-ExtraLight.eot);
    src: local('Helvetica Now Micro Extra Light'), local('HelveticaNowMicro-ExtraLight'),
        url(/fonts/HelveticaNowMicro-ExtraLight.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowMicro-ExtraLight.woff2) format('woff2'),
        url(/fonts/HelveticaNowMicro-ExtraLight.woff) format('woff'),
        url(/fonts/HelveticaNowMicro-ExtraLight.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Micro';
    src: url(/fonts/HelveticaNowMicro-LightItalic.eot);
    src: local('Helvetica Now Micro Light Ita'), local('HelveticaNowMicro-LightItalic'),
        url(/fonts/HelveticaNowMicro-LightItalic.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowMicro-LightItalic.woff2) format('woff2'),
        url(/fonts/HelveticaNowMicro-LightItalic.woff) format('woff'),
        url(/fonts/HelveticaNowMicro-LightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Micro';
    src: url(/fonts/HelveticaNowMicro-Medium.eot);
    src: local('Helvetica Now Micro Medium'), local('HelveticaNowMicro-Medium'),
        url(/fonts/HelveticaNowMicro-Medium.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowMicro-Medium.woff2) format('woff2'),
        url(/fonts/HelveticaNowMicro-Medium.woff) format('woff'),
        url(/fonts/HelveticaNowMicro-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Micro';
    src: url(/fonts/HelveticaNowMicro-RegIta.eot);
    src: local('Helvetica Now Micro Reg Ita'), local('HelveticaNowMicro-RegIta'),
        url(/fonts/HelveticaNowMicro-RegIta.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowMicro-RegIta.woff2) format('woff2'),
        url(/fonts/HelveticaNowMicro-RegIta.woff) format('woff'),
        url(/fonts/HelveticaNowMicro-RegIta.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Micro';
    src: url(/fonts/HelveticaNowMicro-BoldItalic.eot);
    src: local('Helvetica Now Micro Bold Italic'), local('HelveticaNowMicro-BoldItalic'),
        url(/fonts/HelveticaNowMicro-BoldItalic.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowMicro-BoldItalic.woff2) format('woff2'),
        url(/fonts/HelveticaNowMicro-BoldItalic.woff) format('woff'),
        url(/fonts/HelveticaNowMicro-BoldItalic.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Micro';
    src: url(/fonts/HelveticaNowMicro-Bold.eot);
    src: local('Helvetica Now Micro Bold'), local('HelveticaNowMicro-Bold'),
        url(/fonts/HelveticaNowMicro-Bold.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowMicro-Bold.woff2) format('woff2'),
        url(/fonts/HelveticaNowMicro-Bold.woff) format('woff'),
        url(/fonts/HelveticaNowMicro-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Micro';
    src: url(/fonts/HelveticaNowMicro-Regular.eot);
    src: local('Helvetica Now Micro Regular'), local('HelveticaNowMicro-Regular'),
        url(/fonts/HelveticaNowMicro-Regular.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowMicro-Regular.woff2) format('woff2'),
        url(/fonts/HelveticaNowMicro-Regular.woff) format('woff'),
        url(/fonts/HelveticaNowMicro-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Micro';
    src: url(/fonts/HelveticaNowMicro-ExtraBold.eot);
    src: local('Helvetica Now Micro Extra Bold'), local('HelveticaNowMicro-ExtraBold'),
        url(/fonts/HelveticaNowMicro-ExtraBold.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowMicro-ExtraBold.woff2) format('woff2'),
        url(/fonts/HelveticaNowMicro-ExtraBold.woff) format('woff'),
        url(/fonts/HelveticaNowMicro-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-BlackItalic.eot);
    src: local('Helvetica Now Text  Black Ita'), local('HelveticaNowText-BlackItalic'),
        url(/fonts/HelveticaNowText-BlackItalic.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-BlackItalic.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-BlackItalic.woff) format('woff'),
        url(/fonts/HelveticaNowText-BlackItalic.ttf) format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-ExtBdIta.eot);
    src: local('Helvetica Now Text  ExtBd Ita'), local('HelveticaNowText-ExtBdIta'),
        url(/fonts/HelveticaNowText-ExtBdIta.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-ExtBdIta.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-ExtBdIta.woff) format('woff'),
        url(/fonts/HelveticaNowText-ExtBdIta.ttf) format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-Black.eot);
    src: local('Helvetica Now Text  Black'), local('HelveticaNowText-Black'),
        url(/fonts/HelveticaNowText-Black.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-Black.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-Black.woff) format('woff'),
        url(/fonts/HelveticaNowText-Black.ttf) format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-BoldItalic.eot);
    src: local('Helvetica Now Text  Bold Italic'), local('HelveticaNowText-BoldItalic'),
        url(/fonts/HelveticaNowText-BoldItalic.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-BoldItalic.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-BoldItalic.woff) format('woff'),
        url(/fonts/HelveticaNowText-BoldItalic.ttf) format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-Bold.eot);
    src: local('Helvetica Now Text  Bold'), local('HelveticaNowText-Bold'),
        url(/fonts/HelveticaNowText-Bold.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-Bold.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-Bold.woff) format('woff'),
        url(/fonts/HelveticaNowText-Bold.ttf) format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-RegIta.eot);
    src: local('Helvetica Now Text  Reg Ita'), local('HelveticaNowText-RegIta'),
        url(/fonts/HelveticaNowText-RegIta.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-RegIta.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-RegIta.woff) format('woff'),
        url(/fonts/HelveticaNowText-RegIta.ttf) format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-ExtLtIta.eot);
    src: local('Helvetica Now Text  ExtLt Ita'), local('HelveticaNowText-ExtLtIta'),
        url(/fonts/HelveticaNowText-ExtLtIta.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-ExtLtIta.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-ExtLtIta.woff) format('woff'),
        url(/fonts/HelveticaNowText-ExtLtIta.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-Thin.eot);
    src: local('Helvetica Now Text  Thin'), local('HelveticaNowText-Thin'),
        url(/fonts/HelveticaNowText-Thin.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-Thin.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-Thin.woff) format('woff'),
        url(/fonts/HelveticaNowText-Thin.ttf) format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-ExtraLight.eot);
    src: local('Helvetica Now Text  Extra Light'), local('HelveticaNowText-ExtraLight'),
        url(/fonts/HelveticaNowText-ExtraLight.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-ExtraLight.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-ExtraLight.woff) format('woff'),
        url(/fonts/HelveticaNowText-ExtraLight.ttf) format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-Light.eot);
    src: local('Helvetica Now Text  Light'), local('HelveticaNowText-Light'),
        url(/fonts/HelveticaNowText-Light.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-Light.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-Light.woff) format('woff'),
        url(/fonts/HelveticaNowText-Light.ttf) format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-ExtraBold.eot);
    src: local('Helvetica Now Text  Extra Bold'), local('HelveticaNowText-ExtraBold'),
        url(/fonts/HelveticaNowText-ExtraBold.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-ExtraBold.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-ExtraBold.woff) format('woff'),
        url(/fonts/HelveticaNowText-ExtraBold.ttf) format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-MediumItalic.eot);
    src: local('Helvetica Now Text  Med Ita'), local('HelveticaNowText-MediumItalic'),
        url(/fonts/HelveticaNowText-MediumItalic.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-MediumItalic.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-MediumItalic.woff) format('woff'),
        url(/fonts/HelveticaNowText-MediumItalic.ttf) format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-Regular.eot);
    src: local('Helvetica Now Text  Regular'), local('HelveticaNowText-Regular'),
        url(/fonts/HelveticaNowText-Regular.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-Regular.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-Regular.woff) format('woff'),
        url(/fonts/HelveticaNowText-Regular.ttf) format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-LightItalic.eot);
    src: local('Helvetica Now Text  Light Ita'), local('HelveticaNowText-LightItalic'),
        url(/fonts/HelveticaNowText-LightItalic.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-LightItalic.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-LightItalic.woff) format('woff'),
        url(/fonts/HelveticaNowText-LightItalic.ttf) format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-ThinItalic.eot);
    src: local('Helvetica Now Text  Thin Italic'), local('HelveticaNowText-ThinItalic'),
        url(/fonts/HelveticaNowText-ThinItalic.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-ThinItalic.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-ThinItalic.woff) format('woff'),
        url(/fonts/HelveticaNowText-ThinItalic.ttf) format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Helvetica Now Text';
    src: url(/fonts/HelveticaNowText-Medium.eot);
    src: local('Helvetica Now Text  Medium'), local('HelveticaNowText-Medium'),
        url(/fontsHelveticaNowText-Medium.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-Medium.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-Medium.woff) format('woff'),
        url(/fonts/HelveticaNowText-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NeueKabel Black';
    src: url(/fonts/NeueKabel-Black.eot);
    src: local('NeueKabel Black'), local('NeueKabel-Black'),
        url(/fontsHelveticaNowText-Medium.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-Medium.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-Medium.woff) format('woff'),
        url(/fonts/HelveticaNowText-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'NeueKabel Bold';
    src: url(/fonts/NeueKabel-Bold.eot);
    src: local('NeueKabel Bold'), local('NeueKabel-Bold'),
        url(/fontsHelveticaNowText-Medium.eot?#iefix) format('embedded-opentype'),
        url(/fonts/HelveticaNowText-Medium.woff2) format('woff2'),
        url(/fonts/HelveticaNowText-Medium.woff) format('woff'),
        url(/fonts/HelveticaNowText-Medium.ttf) format('truetype');
    font-weight: 500;
    font-style: normal;
}